/*
 *
 *   SKIN 1 - INSPINIA - Responsive Admin Theme
 *   NAME - Blue light
 *
*/

@use 'sass:color';

// Main colors
$skin-1-color: #0e9aef;
$skin-1-nav-bg: #3e495f;
$skin-1-a-color: #9ea6b9;
$skin-1-page-bg: #f4f6fa;

// Custom style
.skin-1 .minimalize-styl-2 {
  margin: 14px 5px 5px 30px;
}

.skin-1 .navbar-top-links li:last-child {
  margin-right: 30px;
}

.skin-1.fixed-nav .minimalize-styl-2 {
  margin: 14px 5px 5px 15px;
}

.skin-1 .spin-icon {
  background: $skin-1-color !important;
}

.skin-1 .nav-header {
  background-color: $skin-1-color;
  background: url('../../../images/inspinia/patterns/header-profile-skin-1.png');
}

.skin-1.mini-navbar .nav-second-level {
  background: $skin-1-nav-bg;
}

.skin-1 .breadcrumb {
  background: transparent;
}

.skin-1 .page-heading {
  border: none;
}

.skin-1 .nav>li.active {
  background: color.adjust($skin-1-nav-bg, $lightness: -2%);
}

.skin-1 .nav>li>a {
  color: $skin-1-a-color;
}

.skin-1 ul.nav-second-level {
  background-color: inherit;
}

.skin-1 .nav>li.active>a {
  color: #fff;
}

.skin-1 .navbar-minimalize {
  background: $skin-1-color;
  border-color: $skin-1-color;
}

body.skin-1 {
  background: $skin-1-nav-bg;
}

.skin-1 .navbar-static-top {
  background: #ffffff;
}

.skin-1 .dashboard-header {
  background: transparent;
  border-bottom: none !important;
  border-top: none;
  padding: 20px 30px 10px 30px;
}

.fixed-nav.skin-1 .navbar-fixed-top {
  background: #fff;
}

.skin-1 .wrapper-content {
  padding: 20px 10px 90px;
}

.skin-1 #page-wrapper {
  background: $skin-1-page-bg;
}

.skin-1 .ibox-title,
.skin-1 .ibox-content {
  border-width: 1px;
}

.skin-1 .ibox-content:last-child {
  border-style: solid solid solid solid;
}

.skin-1 .nav>li.active {
  border: none;
}

.skin-1 .nav-header {
  padding: 35px 25px 25px 25px;
}

.skin-1 .nav-header a.dropdown-toggle {
  color: #fff;
  margin-top: 10px;
}

.skin-1 .nav-header a.dropdown-toggle .text-muted {
  color: #fff;
  opacity: 0.8;
}

.skin-1 .profile-element {
  text-align: center;
}

.skin-1 .rounded-circle {
  border-radius: 5px;
}

.skin-1 .navbar-default .nav>li>a:hover,
.skin-1 .navbar-default .nav>li>a:focus {
  background: color.adjust($skin-1-nav-bg, $lightness: -2%);
  color: #fff;
}

.skin-1 .nav.nav-tabs>li.active>a {
  color: #555;
}

.skin-1 .nav.nav-tabs>li.active {
  background: transparent;
}
