/*
 *
 *   SKIN 2 - INSPINIA - Responsive Admin Theme
 *   NAME - Inspinia Ultra
 *
*/

@use 'sass:color';

// Main colors
$skin-2-color: #23c6c8;
$skin-2-nav-bg: #ededed;
$skin-2-a-color: #999c9e;
$skin-2-nav-top-bg: #213a53;

// Custom style
.skin-2 .minimalize-styl-2 {
  margin: 14px 5px 5px 25px;
}

.skin-2 .navbar-top-links li:last-child {
  margin-right: 30px;
}

.skin-2.fixed-nav .minimalize-styl-2 {
  margin: 14px 5px 5px 15px;
}

.skin-2 .spin-icon {
  background: $skin-2-color !important;
}

.skin-2 .nav-header {
  background-color: $skin-2-color;
  background: url('../../../images/inspinia/patterns/header-profile-skin-2.png');
}

.skin-2.mini-navbar .nav-second-level {
  background: $skin-2-nav-bg;
}

.skin-2 .breadcrumb {
  background: transparent;
}

.skin-2 .page-heading {
  border: none;
  background: rgba(255, 255, 255, 0.7);
}

.skin-2 .nav>li.active {
  background: color.adjust($skin-2-nav-bg, $lightness: -5%);
}

.skin-2 .nav>li>a {
  color: $skin-2-a-color;
}

.skin-2 ul.nav-second-level {
  background-color: inherit;
}

.skin-2 .nav>li.active>a {
  color: $skin-2-nav-top-bg;
}

.skin-2 .navbar-minimalize {
  background: $skin-2-color;
  border-color: $skin-2-color;
}

body.skin-2 {
  color: #565758 !important;
}

body.skin-2 #wrapper {
  background: $skin-2-nav-bg;
}

.skin-2 .navbar-static-top {
  background: $skin-2-nav-top-bg;
}

.skin-2 .dashboard-header {
  background: rgba(255, 255, 255, 0.4);
  border-bottom: none !important;
  border-top: none;
  padding: 20px 30px 20px 30px;
}

.fixed-nav.skin-2 .navbar-fixed-top {
  background: $skin-2-nav-top-bg;
  border-bottom: none !important;
}

.skin-2 .wrapper-content {
  padding: 20px 10px 90px;
}

.skin-2 #page-wrapper {
  background: rgba(246, 246, 246, 1);
  background: -webkit-radial-gradient(center, ellipse cover, rgba(246, 246, 246, 1) 20%, rgba(213, 213, 213, 1) 100%);
  background: -o-radial-gradient(center, ellipse cover, rgba(246, 246, 246, 1) 20%, rgba(213, 213, 213, 1) 100%);
  background: -ms-radial-gradient(center, ellipse cover, rgba(246, 246, 246, 1) 20%, rgba(213, 213, 213, 1) 100%);
  background: radial-gradient(ellipse at center, rgba(246, 246, 246, 1) 20%, rgba(213, 213, 213, 1) 100%);
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#f6f6f6, endColorstr=#d5d5d5)";
}

.skin-2 .ibox-title {
  background: rgba(255, 255, 255, 0.7);
  border: none;
  margin-bottom: 1px;
}

.skin-2 .ibox-content {
  background: rgba(255, 255, 255, 0.4);
  border: none !important;
}

.skin-2 .ibox-title,
.skin-2 .ibox-content {
  border-width: 1px;
}

.skin-2 .ibox-content:last-child {
  border-style: solid solid solid solid;
}

.skin-2 .nav>li.active {
  border: none;
}

.skin-2 .nav-header {
  padding: 35px 25px 25px 25px;
}

.skin-2 .nav-header a.dropdown-toggle {
  color: $skin-2-a-color;
  margin-top: 10px;
}

.skin-2 .nav-header a.dropdown-toggle .text-muted {
  color: $skin-2-a-color;
  opacity: 0.8;
}

.skin-2 .rounded-circle {
  border-radius: 10px;
}

.skin-2 .nav.navbar-top-links>li>a:hover,
.skin-2 .nav.navbar-top-links>li>a:focus {
  background: color.adjust($skin-2-nav-top-bg, $lightness: -5%);
}

.skin-2 .navbar-default .nav>li>a:hover,
.skin-2 .navbar-default .nav>li>a:focus {
  background: #e0e0e0;
  color: #213a53;
}

.skin-2 .nav.nav-tabs>li.active>a {
  color: #555;
}

.skin-2 .nav.nav-tabs>li.active {
  background: transparent;
}

.skin-2 .border-bottom {
  border-bottom: none !important;
}

.skin-2 #side-menu>li>a,
.skin-2 .nav.nav-second-level>li>a {
  color: #586b7d;
}

.skin-2.mini-navbar .nav-header {
  background: $skin-2-nav-top-bg;
}
