/*
 *
 *   SKIN 3 - INSPINIA - Responsive Admin Theme
 *   NAME - Yellow/purple
 *
*/

@use 'sass:color';

// Main colors
$skin-3-color: #ecba52;
$skin-3-nav-bg: #3e2c42;
$skin-3-a-color: #948b96;
$skin-3-page-bg: #f4f6fa;

// Custom style
.skin-3 .minimalize-styl-2 {
  margin: 14px 5px 5px 30px;
}

.skin-3 .navbar-top-links li:last-child {
  margin-right: 30px;
}

.skin-3.fixed-nav .minimalize-styl-2 {
  margin: 14px 5px 5px 15px;
}

.skin-3 .spin-icon {
  background: $skin-3-color !important;
}

.skin-3 .nav-header {
  background-color: $skin-3-color;
  background: url('../../../images/inspinia/patterns/header-profile-skin-3.png');
}

.skin-3.mini-navbar .nav-second-level {
  background: $skin-3-nav-bg;
}

.skin-3 .breadcrumb {
  background: transparent;
}

.skin-3 .page-heading {
  border: none;
}

.skin-3 .nav>li.active {
  background: color.adjust($skin-3-nav-bg, $lightness: -2%);
}

.skin-3 .nav>li>a {
  color: $skin-3-a-color;
}

.skin-3 ul.nav-second-level {
  background-color: inherit;
}

.skin-3 .nav>li.active>a {
  color: #fff;
}

.skin-3 .navbar-minimalize {
  background: $skin-3-color;
  border-color: $skin-3-color;
}

body.skin-3 {
  background: $skin-3-nav-bg;
}

.skin-3 .navbar-static-top {
  background: #ffffff;
}

.skin-3 .dashboard-header {
  background: transparent;
  border-bottom: none !important;
  border-top: none;
  padding: 20px 30px 10px 30px;
}

.fixed-nav.skin-3 .navbar-fixed-top {
  background: #fff;
}

.skin-3 .wrapper-content {
  padding: 20px 10px 90px;
}

.skin-3 #page-wrapper {
  background: $skin-3-page-bg;
}

.skin-3 .ibox-title,
.skin-3 .ibox-content {
  border-width: 1px;
}

.skin-3 .ibox-content:last-child {
  border-style: solid solid solid solid;
}

.skin-3 .nav>li.active {
  border: none;
}

.skin-3 .nav-header {
  padding: 35px 25px 25px 25px;
}

.skin-3 .nav-header a.dropdown-toggle {
  color: #fff;
  margin-top: 10px;
}

.skin-3 .nav-header a.dropdown-toggle .text-muted {
  color: #fff;
  opacity: 0.8;
}

.skin-3 .profile-element {
  text-align: center;
}

.skin-3 .rounded-circle {
  border-radius: 5px;
}

.skin-3 .navbar-default .nav>li>a:hover,
.skin-3 .navbar-default .nav>li>a:focus {
  background: color.adjust($skin-3-nav-bg, $lightness: -2%);
  color: #fff;
}

.skin-3 .nav.nav-tabs>li.active>a {
  color: #555;
}

.skin-3 .nav.nav-tabs>li.active {
  background: transparent;
}
